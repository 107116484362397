import React, { useState, useEffect, useCallback } from "react";
import "./FrostedNav.css";
import logo from "../../../images/logos/know-your-processes-logo-cutout.png";
export default function FrostedNav(props) {
  const [lastScrollY, setLastScrollY] = useState(0);
  const [navVisible, setNavVisible] = useState(true);

  const handleScroll = useCallback(() => {
    const currentScrollY = window.scrollY;
    if (currentScrollY > lastScrollY) {
      setNavVisible(false);
    } else {
      setNavVisible(true);
    }
    setLastScrollY(currentScrollY);
  }, [lastScrollY]); // dependency array here includes lastScrollY

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]); // now handleScroll is included in useEffect dependency array

  return (
    <nav className={`frosted-nav ${navVisible ? "visible" : "hidden"}`}>
      <a href="/">
        <img src={logo} alt="Logo" className="nav-logo" />
      </a>
      <ul>
        <li>
          <a href="/">Home</a>
        </li>
        <li>
          <a href="/services">Services</a>
        </li>
        <li>
          <a href="/software">Software</a>
        </li>
        <li>
          <a href="/about">About</a>
        </li>
        <li>
          <a href="/careers">Careers</a>
        </li>
        <li
          style={{ cursor: "pointer" }}
          onClick={() => {
            props.toggleModal();
            props.setFormTitle("Contact");
          }}
        >
          Contact
        </li>
      </ul>
    </nav>
  );
}
