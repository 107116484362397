import React from "react";
import "./MainHeadlineSubOpacity.css";
import Alexmaracinarubutton from "../../../components/buttons/Alexmaracinarubutton/Alexmaracinarubutton";
import { useNavigate } from "react-router-dom";

import logo from "../../../images/logos/know-your-processes-logo-cutout.png";

export default function MainHeadlineSubOpacity(props) {
  const navigate = useNavigate();

  // Function to handle button click
  const handleApplyClick = () => {
    // Navigate to a dynamic route based on the job title
    const GoToAbout = props.goToLink;
    navigate(GoToAbout);

    window.scrollTo({
      top: 0,
      behavior: "smooth", // This enables the smooth scrolling effect
    });
  };

  return (
    <div className="MainHeadlineSubOpacity__container">
      <div className="content">
        <h1>{props.mainTitle}</h1>
        <h2>{props.subtitle}</h2>
        <img className="main-headline-logo" src={props.Logo} alt="logo" />
        {props.button && <Alexmaracinarubutton handleApplyClick={handleApplyClick} Text={props.button} />}
      </div>
    </div>
  );
}
