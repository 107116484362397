import React from "react";
import "./Software.css";
import MainHeadlineSubOpacity from "../../components/container-components/MainHeadlineSubOpacity/MainHeadlineSubOpacity";
import Streamline from "../../images/backgrounds/Ad Universe.png";
import Logo from "../../images/logos/Cropped_Flortask_Logo.png";
import PrimarySection from "../../components/Sections/PrimarySection/PrimarySection";
import Image from "../../images/graphics/Dashboard.png";
import { useNavigate } from "react-router-dom";
import CTAButtonSection from "../../components/Sections/CTAButtonSection/CTAButtonSection";

export default function Software(props) {
  const navigate = useNavigate();

  const handleApplyClick = () => {
    // Navigate to a dynamic route based on the job title
    const GoToServices = "/services";
    navigate(GoToServices);

    window.scrollTo({
      top: 0,
      behavior: "smooth", // This enables the smooth scrolling effect
    });
  };

  return (
    <div>
      <MainHeadlineSubOpacity
        Logo={Logo}
        backgroundImg={Streamline}
        mainTitle="Introducing Flowtask!"
        subtitle=" Process & User management software to allocate work and duties"
        // button="LEARN MORE"
        toggleModal={props.toggleModal}
        isOpen={props.isOpen}
        setIsOpen={props.setIsOpen}
        type="LEARN MORE"
        title="LEARN MORE"
        setFormTitle={props.setFormTitle}
        goToLink="/about"
      />
      <PrimarySection
        Image={Image}
        title="Founded on the principle that every organization"
        main="No matter the size, can benefit from streamlined operations, we provide a tool that adapts to your needs. Whether you're a small business owner or running a not-for-profit, our solution molds to your unique challenges."
        secondary="Discover how we can make your business operations more efficient and fun!"
        button={true}
        buttonLink={true}
        handleApplyClick={handleApplyClick}
      />
      <CTAButtonSection
        toggleModal={props.toggleModal}
        isOpen={props.isOpen}
        setIsOpen={props.setIsOpen}
        formTitle={props.formTitle}
        setFormTitle={props.setFormTitle}
        title="Are you ready to take control of your business processes?"
        buttonOne={true}
        buttonOneText="Subscribe to Our SaaS"
        buttonTwo={true}
        buttonTwoText="Let Us Document Your Processes Today"
        // bottomText="and transform the way you work today!"
      />
    </div>
  );
}
